@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

:root {
  --primary-color: #ffffff;
  --background-start: #000000;
  --background-mid: #1a0033;
  --background-end: #330066;
  --glow-color: rgba(160, 32, 240, 0.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.landing-page {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, var(--background-start) 0%, var(--background-mid) 50%, var(--background-end) 100%);
  overflow: hidden;
}

.particle-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  text-align: center;
  color: var(--primary-color);
  z-index: 1;
  padding: 1rem;
  width: 90%;
  max-width: 800px;
}

h1 {
  font-weight: 700;
  font-size: clamp(2rem, 10vw, 6rem);
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-shadow:
    0 0 10px var(--glow-color),
    0 0 20px rgba(160, 32, 240, 0.5),
    0 0 30px rgba(160, 32, 240, 0.3);
  line-height: 1.2;
  word-wrap: break-word;
}

p {
  font-weight: 400;
  font-size: clamp(1rem, 4vw, 1.5rem);
  margin-top: 0.5rem;
  text-shadow:
    0 0 5px var(--glow-color),
    0 0 10px rgba(160, 32, 240, 0.5),
    0 0 15px rgba(160, 32, 240, 0.3);
  line-height: 1.5;
}

.copyright {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: clamp(0.6rem, 2vw, 0.8rem);
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0 0 5px rgba(160, 32, 240, 0.5);
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }

  .copyright {
    bottom: 5px;
    right: 10px;
  }
}

@media (max-height: 500px) {
  h1 {
    font-size: clamp(1.5rem, 8vh, 4rem);
  }

  p {
    font-size: clamp(0.8rem, 3vh, 1.2rem);
  }
}